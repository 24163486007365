import Box from "@mui/material/Box";
import type { NextPage } from "next";
import React from "react";
import Content from "../components/Content";
import { contents } from "../contents/home";
import reportWebVitals from "../reportWebVitals";

const Home: NextPage = () => {
  return (
    <Box>
      {contents.map((content, i) => {
        return <Content key={i} index={i} {...content} />;
      })}
    </Box>
  );
};
export default Home;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
