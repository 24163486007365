import Image from "next/image";
import React from "react";
import {
  contentList,
  ContentProps,
  descriptions,
  elements,
  images,
  list,
  title,
} from "./type";

const functionContents = [
  {
    title: "認識/検索機能",
    items: [
      {
        title: "該当する部品を含んだ図面を検索",
        descriptions: [
          "図面内に記載されている部品や文字・記号情報を個別にまたは組み合わせて検索し、対象となる情報を含んだ図面を表示します。",
          "部品や記号など、大きさの違いによる識別率のバラつきもある程度考慮しています。",
        ],
      },
      {
        title: "図面内の該当する部品・注書き等の位置を認識し、マークを表示",
        descriptions: [
          "検索した図面の中の図面を検索する際に対象とした部品・注書きなどの位置に、マークを表示します。",
          "AIが何を識別したのかを、確認することができます。",
        ],
      },
      {
        title: "図面内の文字情報の位置及び文字情報の認識(手書き文字も含む)",
        descriptions: [
          "検索しようとする図面の中に記述されている文字の位置を見つけ、その文字情報を識別することができます。",
          "文字は、手書き文字の位置判別・文字識別も可能です。",
          "図面中のさまざまなアラインメントの角度の文字の識別も可能です。",
        ],
      },
      {
        title:
          "熟練設計者が検索する際に注目した点を記録し、若手設計者と共有可能",
        descriptions: [
          "熟練設計者が検索する手順や複数表示された候補から選択するポイント等を記録する機能を提供します。",
          "若手・中堅技術者は、それらの記録を参考にして、自分の検索に活かすことができます。",
          "その機能により、偏在しているノウハウを、社内で共有することができるようになります。",
        ],
      },
      {
        title:
          "設計図面において、AI機能を学習する際のラベルを表示し、修正も可能",
        descriptions: [
          "検索された図面の中に、検索した際に識別した部品・記号・文字位置にラベル表示をします。",
          "そのラベル位置のズレや、誤りがあった場合に、利用者がその位置を修正し保存することで、修正されたラベル情報のデータを利用して再学習を行うことができます。",
          "ラベル付けの作業を、わざわざ外部に委託しなくても、利用者が実際の識別結果を見ながら、修正することで、AIのより正確な判断ができるよう、学習データを増やしていくことが可能となります。",
        ],
      },
      {
        title: "仕様情報の管理",
        descriptions: [
          "仕様情報を構造化して保存し、部品と仕様情報を関連付けます。これにより仕様情報から部品および部品を含んだ図面を検索することができます。",
        ],
      },
    ],
  },
  {
    title: "管理機能",
    items: [
      {
        title: "認識エンジンの追加/再学習の機能",
        descriptions: [
          "部品や文字等を識別できるようにするためには、多くのデータが必要となります。",
          "しかし、初めから多くのデータを用意し、ラベルを付加する作業をするのは時間がかかり、その労力も大きくなるため、ちょっと試してみるということが難しい状況だといえます。",
          "LearningBookではお持ちの図面の中から少ないデータで学習を開始し、利用しながらデータを追加・再学習をする機能を用意しており、先ずはPoC(Proof of Concept)を試してみることが可能です。",
        ],
      },
      {
        title:
          "学習したデータの認識率を考慮し、適切なAIエンジンを選択しシステムに利用することになります。",
        descriptions: [
          "LearningBookでは、AIエンジンの新規作成から利用・再学習等一連のライフサイクルに対応する各種管理機能も提供します。",
        ],
      },
      {
        title: "学習データとAI機能の関連管理機能",
        descriptions: [
          "AIエンジンは認識精度を向上させるために、何度も学習を繰り返す必要があり、学習の効果を測定するためには利用したデータとそれにより生成されたAI機構の精度などを正しく管理しておくことが必要となります。LearningBookでは学習に利用したデータと生成されたAIエンジンを管理する機能を用意しており、ユーザーはシステムの利用を通じてAIエンジンの再学習のサイクルによる学習効果を評価できるようになっています。",
        ],
      },
    ],
  },
  {
    title: "システム連携機能",
    items: [
      {
        title: "AI機能のシステム展開(予定)",
        descriptions: [
          "LearningBookで学習したAIエンジンを、お客様のシステムに組み込み利用いただくこともあると思います。LearningBookでは、お客様が学習させたAIエンジンを、お客様のシステムで利用いただけるよう、モジュールとして活用いただけるようにいたします。",
        ],
      },
    ],
  },
];

const exampleOfUseContents = [
  {
    title: "設計業務での利用",
    descriptions: [
      "新たな設計を行う際に、過去実績のある機構を持った適切な設計図面を参考にすることで、設計業務を効率的に行うことがあります。その場合に、参考としたい部品・機構や、注書き等の情報を用い、それらが記載されている図面を検索・表示し、活用することができるようになります。",
    ],
  },
  {
    title: "設計レビュー等での利用",
    descriptions: [
      "設計レビューの際に、過去設計時の改良の履歴や、改善のポイントなどの注釈が参考になることがあります。それらの情報を、累積された図面の中から抽出し、活用することができるようになります。",
    ],
  },
  {
    title: "不具合検証業務での利用",
    descriptions: [
      "製品の不具合が発生した場合、過去類似の不具合の有無やその情報などを確認し、不具合の原因調査に役立てることがあります。図面中に記載されたそのような情報や、該当部品を利用している製品図面を網羅的に検索し、その中から有用な図面や情報だけを抽出し、活用いただけるようになります。",
    ],
  },
  {
    title: "購買部門での利用",
    descriptions: [
      "部品発注の際に、発注する部品の見積額の妥当性を確認するために、過去の類似部品の図面及びその際の注文情報を検索し、比較検討に活用いただくことが可能です。",
    ],
  },
];

export const contents: ContentProps[] = [
  {
    backgroundImage: "url('learn-book.jpg')",
    height: (isWideScreen) => (isWideScreen ? "480px" : "280px"),
    contents: [
      elements([
        {
          fontSize: "20px",
          lineHeight: 1.4,
          color: "#333",
          marginBottom: "16px",
          description:
            "これまで作成した図面の活用と設計業務の効率化を考えておられる方に",
        },
        <Image
          key={"logo"}
          src={"/learningbook-logo.png"}
          width={"674"}
          height={"80"}
          alt={"learning book logo"}
        />,
        {
          fontSize: "24px",
          lineHeight: 1.6,
          color: "#333",
          description: "AI図面情報抽出・検索AIサービス",
        },
      ]),
    ],
  },
  {
    backgroundColor: "#2B3E50",
    color: "#fff",
    contents: [
      title("こんな課題はありませんか？"),
      descriptions([
        "設計業務に携わる技術者にとって、それまで企業に保存されている図面は、多くの情報が蓄積された貴重な情報源です。設計図面の管理には、図面ファイル名の命名規則や、保管規則を設けるなど、各社で工夫をし、有効に活用できるような仕組みを設けていると思います。",
        "しかし、あの部品を使った製品の図面を探したいとか、図面に追記された不具合原因など貴重な情報が含まれている図面を探したいという場合に、必要な図面を検索することはできるでしょうか?ベテラン技術者の山田さんに聞かないと見つけられないということはありませんか?",
        "LearningBookは、ベテラン技術者のように、蓄積された多くの設計図面の中から、探したい部品や注書きなどの情報を含んだ図面を検索することができます。",
      ]),
    ],
  },
  {
    contents: [
      title("LearningBookの製品特徴"),
      descriptions(
        [
          "LearningBookは図面内に記載されている部品や文字・記号情報を個別または組み合わせることで、対象となる情報を含んだ図面を検索する図面検索AIサービスです。LearningBookには検索の対象物を認識するよう学習させたAIエンジンを利用して、図面ファイル群の中から対象物を検出・蓄積する機能が用意されています。",
          "これにより、若手・中堅設計者でも、社内に蓄積された図面の中から、欲しい図面を効率的に検索することができるようになります。",
        ],
        { marginBottom: "40px" }
      ),
      images([
        {
          title: "LearningBookの構成",
          src: "/lb-structure-1-1024x508.png",
          width: 1024,
          height: 508,
        },
        {
          title: "LearningBookの業務での活用例",
          src: "/lb-example-1024x484.png",
          width: 1024,
          height: 484,
        },
      ]),
    ],
  },
  {
    contents: [title("LearningBookの機能"), contentList(functionContents)],
    backgroundColor: "#eef4f7",
  },
  {
    contents: [title("LearningBookの利用例"), list(exampleOfUseContents)],
    backgroundColor: "#fff",
  },
  {
    backgroundColor: "#eef4f7",
    color: "#333",
    contents: [
      title("LearningBookの操作画面例"),
      images([
        {
          title: "検索キーワード入力例",
          src: "/operation-img-1.jpg",
          width: 720,
          height: 480,
          border: "#00946f 1px solid",
        },
        {
          title: "検索結果表示例",
          src: "/operation-img-2.jpg",
          width: 720,
          height: 480,
          caption: "資料提供協力:株式会社ヤスナ設計工房様",
          border: "#00946f 1px solid",
        },
      ]),
    ],
  },
];
